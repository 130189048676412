<template>
  <div class="page-index">
    <Header
      background-image="images/hero.jpg"
      class="header--center header--center-bottom header--white--text"
    >
      <div class="container">
        <h2 class="card__title">Vind dé makelaar in jouw regio</h2>

        <span class="card__text">
          Vergelijk offertes voordat u beslist en bespaar tot 40%!
        </span>

        <TCard class="header__card">
          <div class="card__form">
            <PostalFormStep :btn-text="btnText" :progress-value="0" />
          </div>
        </TCard>

        <span class="card__subtext"> Vergelijk 6 makelaars in jouw regio </span>
      </div>
    </Header>

    <HomeBlock id="usps">
      <Usps :usps="usps" />
    </HomeBlock>
  </div>
</template>

<script>
import HomeBlock from 'chimera/all/components/HomeBlock'
import AbstractHomePage from 'chimera/all/components/page/AbstractHomePage'
import { ExperimentSource } from 'chimera/all/mixins/usesExperiments'
import Header from 'chimera/all/themes/blueflow/components/layout/Header.vue'
import Usps from 'chimera/all/themes/blueflow/components/Usps'
import PostalFormStep from '~/components/form/steps/postal/PostalFormStep'

export default {
  components: {
    Header,
    HomeBlock,
    PostalFormStep,
    Usps,
  },

  extends: AbstractHomePage,

  layout: 'home',

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Immokantoorvergelijker.be',
      headTitleTemplate: '%s',
      headDescription:
        'Vul uw postcode in en vergelijk geheel gratis 6 offertes van top makelaars uit uw regio. ✓Dé beste prijs voor uw makelaar ✓Bespaar tot 40% ✓100% Gratis!',
      path: '/',
      usps: [
        {
          title: 'Beantwoord een paar vragen',
          text: 'U vertelt ons wat u zoekt, hierdoor kunnen wij de juiste specialist uit uw regio aan u koppelen.',
          logo: require('chimera/all/themes/blueflow/images/usps/pencil.svg'),
        },
        {
          title: 'Ontvang gratis offertes',
          text: 'U ontvangt maximaal zes offertes van specialisten die aan uw behoeften voldoen. Hiermee bespaart u tot 40%!',
          logo: require('chimera/all/themes/blueflow/images/usps/offer.svg'),
        },
        {
          title: 'Kies de juiste specialist',
          text: 'Wij hebben onze specialisten al gecheckt, u hoeft alleen maar de offertes te vergelijken en te kiezen.',
          logo: require('chimera/all/themes/blueflow/images/usps/user.svg'),
        },
      ],
      btnText: 'Ga verder',
      experiment: null,
    }
  },

  /**
   * https://bambelo.atlassian.net/browse/PD-6651
   * @returns {Promise<void>}
   */
  async beforeMount() {
    // https://bambelo.absmartly.com/experiments/341
    this.experiment = await this.$store.dispatch('experiments/get', {
      id: 'PD-6651',
      source: ExperimentSource.ABSMARTLY,
    })

    if (this.experiment) {
      this.btnText =
        this.experiment && this.experiment.isTreatment
          ? 'Zoek in mijn regio'
          : 'Ga verder'
    }
  },
}
</script>

<style lang="scss">
.PD-6651__variant {
  .header {
    .header__container {
      .header__card {
        max-width: 450px;
      }
    }
  }
}
</style>
